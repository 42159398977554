.MuiDialog-container{

  .MuiDialog-paper{
    min-width: 600px;
    padding: 24px;
  }

  .MuiDialogTitle-root{
    padding: 0 0 16px 0 !important;
    font-size: 2rem !important;
  }

  .MuiDialogContent-root{
    padding: 0px !important;
    font-size: 1.6rem !important;
    font-weight: normal !important;
    color: $body-font-color !important;

  }

  .MuiDialogActions-root{
    padding-top: 24px;

    .btn + .btn{
      margin-left: 16px;
    }
  }

}
