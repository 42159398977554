.page-content{
  .analytics-overview-card{


    border: 1px solid #eee;
    padding: 30px;

    .title{
      font-size: 2.4rem;
      text-align: center;
      margin-bottom: 10px;
    }
    .value{
      font-size: 3rem;
      font-weight: bold;
      text-align: center;
    }
  }
}
