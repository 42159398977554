.suggest-card{
  padding: 15px  !important;

  .title{
    padding:0 0 15px 0  !important;

    > span{
      font-size: 1.8rem  !important;
      line-height: 24px !important;
    }
  }

  .description{
    font-size: 1.2rem  !important;
    padding: 0 !important;
    color: #575757 !important;
  }
}
