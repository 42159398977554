.alert{
  padding: 20px !important;
  margin: 20px;
  border: none;
  opacity: 1.0;

  .close{
    top:-10px;
    right: -5px;
    color: #FFF !important;
    opacity: 0.5 !important;
  }

  h4{
    margin: -10px 0 0 0 !important;
    font-size: 2rem !important;
    width: 90%;
  }

  p{
    font-size: 1.4rem !important;
    margin: -10px 0 0 0;
  }

  &.alert-danger{

    background: #EF5350 !important;

    h4{
      color: #FFF !important;
    }
    p{
      color: #FFF  !important;
    }

  }

  &.alert-success{

    background: #388E3C !important;

    h4{
      color: #FFF !important;
    }
    p{
      color: #FFF  !important;
    }

  }

}
