.page-content{

  > div {padding: 0 30px;}

  .recon-result-title{

    h3 {
      margin-left: 24px;
    }

    .recon-executed{
      margin: 4px 0 16px 24px;
    }
    .row + .row{
      margin: 0px 10px 0px;
    }
  }

  .extendable-height{
    padding-bottom: 25px;
      .recon-results-card{
        height: 100%;
        margin: 0;

        div{
          word-break: break-all;

          label{
            display: inline;
          }
        }

      }
  }


 .cz-query-params{
   background: #F5F5F5;
   padding: 16px;
   border-radius: 8px;
   margin: 8px 0;
   .title{
     font-size: 1.5rem;
     font-weight: bold;
     margin: 0 0 16px 0;
   }
 }

 .cz-source-label{
   font-size: 1.6rem;
   font-weight: bold;
 }

  .recon-btn-container{
    text-align: left;
    padding: 10px 25px;

    > .btn{
      width: 200px;
    }
  }

  h3.matched-records-title{
    color: #673AB7;
    margin: 45px 0 20px;
    border-bottom: 1px dotted #BBBBBB;
    padding-bottom: 10px;
  }

  .recon-results-summary{
    padding: 15px 0;
    font-size: 1.4rem;
    margin-top: 15px;
    text-align: right;

    >span{
      margin-right: 30px;
      padding: 8px 16px;
      background: #eee;
      border-radius: 8px;

      >label{
        margin-right: 10px;
      }
    }
  }

  .recon-rule-summary{
    h3{
      margin: 16px 0 !important;
      margin-left: 0;
    }

    .recon-results-summary{
      margin-top: 0;


    }
  }


 .recon-result-tabs{
   margin: 32px 0 0 0;
 }

  .recon-results-card{
    background: #F1F1F1;
    border-radius: 8px;
    border-top: 2px solid $app-brand-color;
    border-bottom: 2px solid $app-brand-color;
    padding: 15px;
    min-height: 165px;
    margin-bottom: 25px;

     h4{
       margin-bottom: 15px !important;
       font-weight: bold;
     }

     > div{
       font-size: 1.4rem;
       margin-top: 10px;
     }
  }

  .rounded-card {

    label{
      font-size: 1.6rem;
      margin-bottom: 15px;
    }

    h3{
      margin-top: 15px;
    }


    &.rule{
      background: #f5f5f5;
      border: 1px solid #eee;
    }

   .recon-rule-delete{
     text-align: right;
     cursor: pointer;
   }


  .more-options{
    display: none;
  }

   &.expanded-card{
     padding: 32px;

     .more-options{
       display: block;
       &.options-inner-container{
         margin: 16px 24px;

         .row{
           display: block;
           margin: 0px;

           + .row{
             margin-top:  30px;
             padding-top: 10px;
           }
         }
         .btn{
           margin-top: 20px;
         }
       }

      h5{
        margin: 10px 0 20px 0;
        padding-bottom: 10px;
        border-bottom: 1px dotted #9e9e9e;
      }

     }

     .actions-menu{
       display: none !important;
     }
   }

  }


  .recon-input{
    .input-file{
      padding-top: 25px;

      .recon-help-text{
        padding: 0 0 15px;
        font-size: 1.5rem;

        > div{
          font-weight: bold;
        }
      }

      .file-error{
        font-size: 1.5rem;
        padding: 10px;
        color: #D32F2F;
      }
    }

  }

  .recon-tabs{
    margin: 30px 0 0 0;

    > div:first-child{
      background: #f1f1f1 !important;

      button{
        color: #000 !important;
      }
    }

    .tab-content{
      padding: 20px;
      background: #fff;
    }
  }

  .recon-details-legend{
    font-size: 1.4rem;
    margin-bottom: 16px;
    padding-left: 16px;

    >span{
      margin-right: 30px;
      >i{
        position: absolute;
        margin-top: -2px;
        color: #1E88E5;
      }

      > span{
        padding: 0 0 0 30px;
      }

      + span{
        margin: 10px 0;
      }

    }
  }

  .data-table{

    &.recon-audit-list {
    tr{

      td{
        pointer-events: none;
        background: #f5f5f5 !important;
        opacity: 0.5;
      }

    }

    tr.Completed{

      td{
        pointer-events:auto;
        background: none  !important;
        opacity: 1.0;
      }

    }

    tr.Error{

      td{
        pointer-events: none;
        background: #fff !important;
        opacity: 0.5;
      }

    }





  }

    .MuiLinearProgress-barColorPrimary{
      background: $green !important;
    }

    .MuiLinearProgress-colorPrimary{

      background: $red !important;
      height: 6px;
      border-radius: 8px;

    }
  }

  .rule-details-table{

    .rule-addnl-details{

      .rule-addnl-details-data{

        > div {
          margin-top: 5px;
          + div{
            margin-top: 20px;
          }
        }

        + .rule-addnl-details-data{
          margin-top: 20px;
        }

      }

    }

  }



  .recon-table-holder{
    overflow: auto;
    width: 100%;
    max-height: 400px;

    table{

      table-layout: auto !important;

      font-size: 1.4rem;

      thead{
        tr{
          th{
            white-space: nowrap;
            background: #f1f1f1;
            padding: 12px;
            border: 1px solid #ddd;
            font-weight: bold;
            text-align: center;



            &.firstfile-column{
              i{
                display: block;
                color: #1E88E5;
              }
            }

            &.secondfile-column{
              i{
                display: block;
                color: #1E88E5;
              }
            }

            .rule{
              font-size: 1.2rem;
              margin-top: 5px;
            }

          }
        }
      }


      &.MuiTable-stickyHeader{

        thead{
          tr:first-child{
            th{
              top:0px;
              z-index: 100;
              height: 80px;
              border: 1px solid #FFF;
              white-space: nowrap!important;


              &:first-child{
                position: sticky;
                left: 0;
                z-index: 200;
              }
            }
          }
          tr:last-child{
            th{
              top:80px;
              height: 80px;
              z-index: 100;
              border: 1px solid #FFF;
              white-space: nowrap !important;
            }
          }
      }

      tbody{

        tr{
          td:first-child{
            position: sticky;
            left: 0;
          }
        }

      }

    }

      tbody{
        tr{
          td{
            white-space: nowrap !important;
            background: #fff;
            padding: 10px;
            border: 1px solid #eee;

            /*&.id{
              position: fixed;
              width: 20px;
            }*/

            &.rec-error{
              font-weight: bold;
              background: #FFCDD2;
            }

            &.fail{
              background: #FFCDD2;
            }
          }

        }
      }
    }

    .unmatched-title{
      div{
        padding: 10px 15px;
      }
      font-size: 1.6rem;
    }
  }







.btn-start-config{
  margin: 30px 0;
}

.rules-bucket{
  .rounded-card{
    margin: 15px 0;

    .rounded-card{
      background: #F5F5F5;

      label{
        margin:0;
      }
    }
  }
}

.display-column-list{
  color: $hyperlink !important;
  cursor: pointer;
  font-size: 1.4rem;
}

.manage-rule-buttons{

  >div + div{
    margin: 15px 0 0 0;
  }

  .createruleset-button{

    min-width: 225px !important;
    background: $app-brand-color!important;
    color: #FFF !important;
    border:1px solid $app-brand-color !important;
    >div{
      display: block;
      margin: -17px 0 0 0;
      span{
        text-align: center;
        font-weight: bold !important;
        font-size: 1.6rem!important;
        text-transform:none !important;

        &.material-icons{
          font-size: 2rem!important;
          margin:7px ​0 0 0;
          position: absolute;
        }
      }
    }
  }


  .import-button{
    input{
      display: none;
    }
    label{
      min-width: 225px;
      padding: 16px!important;
      border: 1px solid #00bcd4;
      cursor: pointer;
      background: #fff;
      text-align: center;
      font-size: 1.5rem;
      color: $app-brand-color !important;
      border-radius: 8px !important;
    }
    .file-error{
      font-size: 1.5rem;
      color: #D32F2F;
    }
  }

}





.manage-ruleset{

  .empty-state-container{
    margin-top: 0px;
  }


.manage-ruleset-table{

  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  width: 100%;


  tr{


    td{
      font-size: 1.4rem;
      padding: 8px 16px;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      background: #fff;


      &.user{

        padding: 20px 20px 20px 40px;

        .material-icons{

          font-size: 14px;
          margin: 4px 0 0 -20px;
          position: absolute;

          &.active{
            color:$green;
          }

          &.inactive{
            color:$red;
          }

        }
      }

      &:first-child{
        cursor: pointer;
        color: $hyperlink;
      }

      &:last-child{
        width: 80px;
        text-align: center;
      }

      .button{
        margin: 0 16px;
        .material-icons{
          font-size: 1.6rem!important;
        }
      }

      }

    }

  }



}

  .cz-container{

    .cz-exe-container{
      /*position: fixed;
      width: 100%;*/
    }

    .cz-recon-results{
      margin-top: 25px;

      .recon-results-summary{
        margin-top: 0px;
        >span:last-child{
          margin-right: 15px;
        }
      }
    }
  }

  .search-section{
    background: #f5f5f5;
    margin: 16px 0;
    padding: 16px;
    border-radius: 4px;
    position: sticky;
    top:0px;
    z-index: 999;
  }



}


label + .MuiInput-formControl{
  min-height: 40px !important;
}


.MuiTextField-root{
  margin: 0px 0 8px 0 !important;

   .MuiFormHelperText-root{
     font-size: 1.4rem !important;
   }
}


.MuiInputLabel-root{
  font-size: 1.8rem !important;
}

.MuiInputBase-root{

  font-size: 1.4rem !important;

  .MuiFormLabel-root{
    border: none !important;
    text-decoration: none !important;
  }
}
.MuiAutocomplete-tag{
  font-size: 1.3rem !important;
}

.rule-content{

  .MuiChip-root{
    color: #fff !important;

    &.source1,&.firstfile{
      background: #2196f3 !important;
    }
    &.Operation{
      background: #607D8B !important;
    }
    &.source2,&.secondfile{
      background: #26a69a !important;
    }
    &.custom{
      background: #AB47BC !important;
    }
  }

  .actions-menu{
    position: absolute !important;
    right: 10px;
    top: 10px;

    button{
      border-radius: 50%;
      &:hover,&:focus{
        background: #eee !important;
      }

    }
  }
}

.valuemap-content{
  .MuiChip-root{
  }

}


.has-backdrop{
  .rounded-card,.page-nav-contianer,h2,h3,.row,.row.extendable-height{
    display: none ;
    &.expanded-card,&.rule-content{
      display: block;
    }
  }
}




.rule-columns-listing{

  > li{
    width: 50%;
    float: left;
      &:first-child > div{
        background: #2196f3 !important;
      }

      &:last-child > div{
        background: #26a69a !important;
      }
  }

  }

  .MuiListSubheader-root{
    font-size: 1.5rem !important;
    font-weight: bold;
    background: #607D8B !important;
    color: #FFF !important;
    border: 2px solid #fff;
    text-transform:uppercase;

  }


.MuiAutocomplete-listbox{
  li{
  font-size: 1.4rem !important;

  }
}

.MuiFormControlLabel-root{


  .MuiSvgIcon-root{
    width: 1.5em !important;
    height: 1.5em !important

  }

  .MuiFormControlLabel-label{
    font-size: 1.6rem !important;
    margin: 2px 25px 0 0 !important;
  }
}


.MuiDialogContent-root{
  .download-btn{
    padding: 8px;
    color: $hyperlink;
    cursor: pointer;

    &:hover{
      color: $hyperlink-hover;
      text-decoration: underline;
    }
  }
}


.data-chips{

   .total-count{
     margin: 10px 0 20px 15px !important;
   }



   > div > div {
     padding: 16px 16px;
     border-radius: 48px;
     font-size: 1.4rem;
     text-align: center;
     border: 1px solid;
     color: #606060;
     background: #FFF;
     cursor: pointer;



     .material-icons{
       position: absolute;
       left: 40px;
       top:16px;
     }

     &.dashboard-all{
       border-color: #757575;
     }

     &.dashboard-pass{
       border-color: #43A047;
     }

     &.dashboard-fail{
       border-color: #C62828;
     }

     &.dashboard-alert{
       border-color: #1E88E5;
     }

     &.dashboard-warning{
       border-color: #F4511E;
     }

     &.dashboard-inprogress {
       border-color: #8E24AA;
     }

     &.selected{
       font-weight: bold;
        pointer-events: none;
     }

      > div:first-child{
        margin-bottom: 5px;
      }

   }

   .none > div{
     border: none;
     background: none;
     padding: 0;
     min-height: 20px;
     margin: 0;
   }

 }

 .download-btn{

   button{
   padding: 16px;
   right:0;
   font-size: 1.4rem;
   color: #673AB7;
   background: #FFF;
   border-radius: 24px;
   border: 1px solid #673AB7;
   cursor: pointer;
   span{
     margin: 0 0 0 8px;
   }


   &:hover{
     color: #673AB7;
     background: #EFEFEF;
     border: 1px solid #673AB7;
   }
 }


 .dropdown-menu{

   a{
     display: block;
     padding: 8px 16px;

     &:hover{
       background: #eee;
     }
   }
 }
 }


 .recon-results-card{
   background: #FFF;
   border-radius: 4px;
   padding: 15px;
   margin-bottom: 25px;
   border:1px solid #EEE;
   min-height: 100px !important;

    h3{
      margin: 5px 0 15px 0 !important;
      font-size: 2rem;

      span{
        font-size: 1.2rem;
        margin-left: 5px;
      }
    }

    > div{
      font-size: 1.4rem;
      margin-top: 10px;
    }

    .row{

      + .row{
        margin-top: 15px;
      }
    }
 }

 .createrule-filecolumns{
   > li
    {
       pointer-events: none;
       font-size: 1.4rem !important;
       padding: 12px 8px !important;

       + li {
         border-top: 1px dotted #eee !important;
       }

     }




 }
