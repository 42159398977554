
 .data-table{

   thead{
     tr{
       th{
         white-space: normal !important;
         font-size: 1.6rem !important;
         font-weight: 400 !important;
       }
     }
   }

   tbody{
     tr{

        td{
          font-size: 1.4rem !important;
          white-space: normal !important;
        }
      }
    }


    &.clickable{

      tbody{
        tr{
            cursor: pointer;

            td:first-child{
              color: #1565c0 !important;
            }

            td{
              .download-btn{
                margin: 0px;
                display: none;
              }

              &.Completed{
                .download-btn{
                  display: block;
                }

              }
            }
         }
       }

    }

    &.short-first-column{
      th:first-child{
        width: 500px;
      }
    }
  }

@media (max-width: 767px) {
  .data-table{
    thead{
      display: none;
    }

    tbody
    {
      tr{
        td{
          position: relative;
          padding:10px 10px 10px 50% !important;
          display: block;
          width: 100% !important;
          height: auto !important;
          font-size: 1.3rem !important;

          &:before {
            content: attr(data-label);
            position: absolute;
            top: 10px;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
