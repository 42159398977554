.login-screen{
  background: #f5f5f5;
  width:40%;
  min-width: 400px;
  margin: 50px auto;
  padding: 40px;
  border-radius: 10px;
  text-align: center;

  /* Change autocomplete styles in WebKit */
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #f5f5f5 inset !important;
}

  h1{
    color: $app-brand-color !important;
  }

  .login-description{
    color: $app-brand-color !important;
    font-size: 2rem;
    text-align: left;
    padding: 24px 16px;

    >div{
      margin: 20px 0 !important;
    }
  }

  .btn-container{
    .btn-action{
      margin: 20px 0;
      button{
        width: 150px !important;
        height: 50px !important;
      }
    }
  }

}


.loading{
  text-align: center;
  font-size: 2rem;
  background: $white;
  padding: 50px 25px;
}


@media (max-width: 767px) {
  .login-screen{
    margin: 0 -15px;
    position: absolute;
    height: 100%;
  }
}
