.margin-top-0 {
	margin-top: 0px !important;
}
.margin-top-5 {
	margin-top: 5px !important;
}
.margin-top-10 {
	margin-top: 10px !important;
}
.margin-top-15 {
	margin-top: 15px !important;
}
.margin-top-20 {
	margin-top: 20px !important;
}
.margin-top-30 {
	margin-top: 30px !important;
}
.margin-top-40 {
	margin-top: 40px !important;
}
.margin-bottom-0 {
	margin-bottom: 0px !important;
}
.margin-bottom-5 {
	margin-bottom: 5px !important;
}
.margin-bottom-10 {
	margin-bottom: 10px !important;
}
.margin-bottom-30 {
	margin-bottom: 30px !important;
}
.margin-right-15 {
	margin-right: 15px !important;
}
.margin-left-20 {
	margin-left: 20px !important;
}
.padding-top-10{
	padding-top: 10px !important;
}
.padding-16{
	padding: 16px !important;
}
.padding-20{
	padding: 20px !important;
}
.ht1000{
	height: 1000px !important;
}
.reduce-zindex{
	z-index: -2 !important;
}
