.textfield{
  width: 100% !important;
}



@media (max-width: 767px) {
  .textfield{
      width: 100% !important;
   }
 }
