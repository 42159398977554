.preloader{

  padding: 50px !important;
  text-align: center;

  > div{
    padding: 0 !important;;
  }
}
;
