.select-container{

  > div {padding: 0 30px;}

  .home-suggestions{
    margin: 30px 0;
    opacity: 1;


    .suggest-card-container{
        display: flex;
        flex-wrap: wrap;
        align-content: stretch;

        > div{
          margin-bottom:30px;

          .suggest-card{
            cursor: pointer;
            height: 100%;
          }
        }
    }

      &.loaded{
        .suggest-card{
          animation: 0.3s ease-out 0s 1 suggestCardAnimation;
          transform-style: preserve-3d;
          -webkit-transform-style: preserve-3d;
        }
      }

      @keyframes suggestCardAnimation {
      0% {
        opacity: 0;
        @include transform(scale(0) translateY(100%) rotateX(-90deg));
      }
      100% {
        opacity: 1;
        @include transform(scale(1) translateY(0) rotateX(0));
      }
    }

  }

@media (max-width: 767px) {
  .home-suggestions{
    .suggest-card-container{

        > div{
          margin-bottom:0px;
        }
     }
   }
 }
}
