.menu-container{



  .mobile-menu-icon
    {
      width: 50px;
      height: 50px;
      cursor: pointer;
      z-index:100;
      position: fixed;


      span{
        width: 30px;
        height: 35px;
        position: absolute;
        top:8px;
        left: 10px;
        @include transition(transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99));

        hr{
          margin: 0;
          position: relative;
          border-top: 0.1rem solid $white;
          @include transition(transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99));
        }

        &.top{
          @include transform(translateY(0));
            hr{
              top:8px;
              @include transform(rotateZ(360deg) );
            }
        }
        &.bottom{
          @include transform(translateY(0px));
            hr{
              top: 24px;
              @include transform(rotateZ(360deg));
            }
        }
        &.middle{
            opacity: 1;
            hr{
              top: 16px;
              width: 80%;
            }
        }
      }

      &.clicked{
        span{
          @include transition(transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99));
          hr{
            @include transition(transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99));
          }
          &.top{
                @include transform(translateY(8px));
                hr{
                  @include transform(rotateZ(-135deg));
                }
          }
          &.bottom{
              @include transform(translateY(-8px));
              hr{
                  @include transform(rotateZ(135deg));
              }
          }
          &.middle{
              opacity: 0;
          }
        }
      }
    }

    .MuiPaper-root{
        top:0px !important;
        background: $menu-bg !important;
        border: none !important;

        /*.product-title{
          color: $app-brand-color;
          font-size: 2rem;
          padding: 15px 10px;
          text-align: center;
          background: #DFDFDF;
        }*/

        .cs-in-app-logo{
          background: #EFEFEF;
          margin: 0 0 0 0;
          padding: 16px;
          border-radius: 0px;
        }

        .product-title{
          color: $app-brand-color;
          background: #EFEFEF;
          font-size: 1.4rem;
          padding: 0 4px 4px 4px;
          margin-bottom: 16px;
          text-align: center;
          > span{
            display: none;
          }

        }

        .welcome{
          text-align: center;
          padding:15px 10px;
          border-bottom: 1px solid #1983A3;
          margin-bottom: 10px;
          display: none;

          .welcome-text{
            font-size: 1.2rem;
            color: $app-brand-text;
          }
          .user{
              font-size: 1.6rem;
              color: $app-brand-text;
              margin-top: 5px;
              text-transform: capitalize;
            }
        }






        .menu-item{
          color: $white !important;
          display: block;

          li.menu-item{
            min-height: 0 !important;
            padding: 10px 10px 5px 10px !important;
            text-align: center;

            div > div{
              line-height: 0 !important;
              padding: 0 18px !important;
            }
          }


          &.context-menu-select{
            padding: 0 18px;
            margin: -16px 0 20px 0;
            cursor: pointer;
            text-transform: capitalize;

            div,li{
              background: #EFEFEF;
              padding: 8px !important;
              border-radius: 0 15px;
              text-align: center;
              width: 40px;
              height: 40px;
              font-size: 1.8rem;
              color: $app-brand-color !important;
            }
          }

          .material-icons{
            font-size: 2.4rem !important;
          }

        }

        .active{
          .menu-item{
            color: #fff !important;
            background: #005A97 !important;
          }
        }

        .profile{
            width: 100%;
            bottom: 70px;
            position: absolute;
        }

        .sign-out{
          padding: 0px;
          text-align: center;
          position: absolute;
          width: 100%;
          bottom: 0px;

          > div{

            &.icon-button{
              color: $white;
              cursor: pointer;
              border: 1px solid $app-brand-color;
              border-radius: 4px !important;
              padding: 5px;
              background: #0089E5 !important;
              font-size: 1.4rem !important;
            }
            background: none !important;
            box-shadow: none !important;
            border-radius: 20px  !important;

            button{
              border: 1px solid #1983A3  !important;
              border-radius: 20px  !important;
              background: $app-brand-color !important;

              > div{
                  >div{
                  border-radius: 20px  !important;
                    span{
                    color: #A3E3F7  !important;
                    text-transform: none !important;
                    padding: 0 40px !important;
                  }
                }
              }
            }
          }
        }

      }

    .menu-overlay{
        top:0px !important;
        background: $black !important;
        opacity: 0.5 !important;
      }
/*--------- Mobile View -----------------*/
    @media (max-width: 767px) {
      .menu-content-container{
          top:50px !important;
          background: $menu-bg-mobile !important;

          .product-title,.welcome{
            display: none;
          }

          .menu-item{
            color: $dark-grey !important;
          }

          .active{
            .menu-item{
              color: $dark-grey !important;
              background: #CECECE !important;
            }
          }


          .sign-out{
            bottom: 50px;
         }
        }

      .menu-overlay{
          top:50px !important;
          background: $black !important;
          opacity: 0.5 !important;
        }
    }


}


.context-menu-container{
  .MuiPaper-root{
      left: 50px !important;
      top:128px !important;
      border-radius: 0px;
      background: #dfdfdf;
  }

  .MuiMenuItem-root{
    display: block !important;
    padding: 8px 16px;
    font-size: 1.4rem;
    text-transform: capitalize;
  }


  .current-context{
    pointer-events: none;
    background: #ababab;
    margin-top: -8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #fff;

    span.current{
      font-size: 1.2rem;
      padding: 4px;
      border-radius: 4px;
      background: #ddd;
      margin: 0 0 0 12px;
    }
  }
}
