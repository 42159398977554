.config-details{

  margin-top: 40px;

  + .config-details{
    margin-top: 100px;
  }

}

.action-holder{
  margin: 40px 0 0 0;
}


.rule-content{

      .MuiAutocomplete-root{

        .MuiTextField-root{
          label + .MuiInputBase-root{
            margin-top: 32px;
          }
        }

        .MuiTextField-root{
          label + .MuiInputBase-root.MuiInputBase-adornedStart{
            margin-top: 23px;
          }
        }
      }




      .MuiTextField-root{
        label + .MuiInputBase-root{
          margin-top: 32px;
        }
      }

}
