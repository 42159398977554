.btn{

  padding: 0;
  border: none;
  min-width: 140px !important;

  span{
    text-transform: none !important;
    font-size: 1.6rem !important;
  }

  &.btn-action{
      background-color: $app-brand-color !important;
      color: $white !important;

      &:hover{
        opacity: 0.9;
        color: $white !important;
      }

  }

  &.btn-default{
      background-color: $light-grey !important;
      color: $black !important;

      &:hover{
        opacity: 0.9;
        color: $black !important;
      }

  }

  &.btn-green{
    button{
      background-color: #558B2F !important;
      span{
        color: #fff !important;
      }
    }
  }

  &.btn-red{
    button{
      background-color: #B71C1C !important;
      span{
        color: #fff !important;
      }
    }
  }


    &:disabled{
        background-color: #E5E5E5 !important;
        span{
          color: #AFAFAF !important;
        }
    }



  + .btn{

margin: 0 0 0 30px;

  }

}
