.usergroup-su{
  margin: 16px 0;
  label{
    font-size: 1.4rem;
  }

  .user-bubble{
    padding: 8px 16px;
    font-size: 1.3rem;
    background: #eee;
    border-radius: 20px;
    margin: 0 8px;
  }
}
