/* ----------- Colors ----------- */
$app-brand-color: #0071BD;
$app-brand-text: #A3E3F7;
$white: #FFFFFF;
$black: #000000;
$dark-grey: #333333;
$light-grey: #E0E0E0;
$icon-red: #F44336;
$icon-yellow:#FFD600;
$icon-green:#2E7D32;
$icon-blue:#1565C0;
$icon-pink:#C2185B;
$icon-orange:#E64A19;
$error-message: #D32F2F;
$green: #43A047;
$red: #EF5350;

$menu-bg: #0071BD;
$menu-bg-mobile: #E1E1E1;
$hyperlink: #2A5DB0;
$hyperlink-hover: #6894DA;



/* ----------- Fonts ----------- */

$app-font-family:'Roboto', Arial ,  sans-serif;
$title-font-color: #333333;
$body-font-color: #606060;
