.page-content{

  .search-section{
    margin: 0 -5px;

    .search-field{
      background: #ececec;
      padding: 5px 15px;
      border-radius: 8px;

      hr{
        display: none;
      }

    }

    .search-btn{
      margin: 9px 0 0 0;
    }

  }

  .favorites-content{
    margin-top: 20px;
  }

  .addSearchItem{
    margin: 10px 0 0 0;
  }

  .error-occured{

    .react-autosuggest__input{
      border-color: $error-message;
    }

    .error-msg{
      color: $error-message;
      font-size: 1.4rem;
      padding: 6px 0;
    }
  }

  .job-details{
    font-size: 14px;

    .job-key-value{
      margin-bottom: 20px;
      > div{
        display: inline-block;
        margin-right: 25px;
        border: 1px dashed #eee;
        padding: 12px;
        background: $white;

        label{
          margin: 0 5px 0 0;
        }
      }
    }

    .log-details{
      padding: 16px;
      background: $white;

      > div{
        min-height: 20px;
      }
    }

    .additional-details{
        padding: 16px;
        background: $white;

        ol{
          margin: 0 0 0 -20px !important;
          li{
            margin-bottom: 16px;
          }
        }
    }
  }

  .create-card-blank{
    margin: 15px 0;
    border-radius: 2px;
    height: 132px;
    background: #eee;
    text-align: center;
    font-size: 1.8rem;
    padding: 35px 0 0 0;
    cursor: pointer;
  }

  .create-card-entry{
    margin: 15px 0;
    border-radius: 2px;
    height: 132px;
    background: #FFF;
    text-align: center;
    font-size: 1.8rem;
    padding: 10px 0 0 0;

    .create-group-name{
      margin-bottom: 20px;
      div:last-child{
        text-align: left;
        font-size: 1.3rem !important;
      }
    }

    .btn{
      bottom: 10px;
    }

  }

}

.snack-bar{
  top:0 !important;
  transform: 'translate3d(0px, -50px, 0px)';
}

.share-modal{

  h3{
    padding-bottom: 30px !important;
  }

  .btn{
    margin: 0 20px 20px 20px;

    + .btn{
      margin-left: 0px;
    }
  }



  .createdby{
    padding: 8px;
    font-size: 13px;
    color: #606060;
    position: absolute;
    margin: -40px 0 0 10px;
  }

 .share-collaborate{
    margin-bottom: 10px;
    position: absolute;
    right: 20px;
    top: 58px;
    font-weight: bold;
 }
  .contacts-list{
    height: 250px;
    overflow: auto;
    margin: 0 -25px -5px;
    padding: 0 25px 15px;

    > div{
      padding: 14px 0 10px 0;
      border-bottom: 1px solid #EEE;
       > div {
          > div{
          margin-bottom: -8px !important;
          + div{
              margin: 0 0 0px 42px !important;
              font-size: 12px;
              &.empty{
                height: 5px;
              }
            }
          }
       }
    }

  }
}

/*--------- Mobile View -----------------*/
@media (max-width: 767px) {

  .job-details{

    [class*="col-"]:nth-child(2) {
        margin-top: 0
      }
  }

  .share-modal{
     > div > div {
       width: 100% !important;
     }
   }

   .job-key-value{
     > div{
       width: 44%;
       margin-right: 20px !important;
       margin-bottom: 10px;
     }
   }
}
