.label-value-pair{
  .MuiTextField-root{
    width: 60%;
  }
}

.password-requirements{

  margin: 16px 0;

  .title{
    font-size: 1.4rem;
    font-weight: bold;
  }

  ul{
    margin: 0 0 0 -24px;
    li{
      font-size: 1.4rem;
    }

  }

}
