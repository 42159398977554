.documentor-graph{

  .documentor-container{
    >div{
      background: $white;
      border-radius: 4px;
    }
  }

  .documentor-step{
    padding: 30px;
    font-size: 1.4rem;
    height: 600px;

    .step-details-btn,.step-details-close{
      display: none;
    }

    .step-details-container{

    }

    .documentor-step-emptystate{
      text-align: left;
      height: 100%;
      display: block;
      font-size: 1.6rem;
      font-weight: bold;
    }

    .documentor-step-details{

      div + div{
        margin-top: 20px;
      }


      label {
        font-weight: bold;
        display: block;
        margin-bottom: 4px;
      }

    }
  }

@media (max-width: 767px) {

  .documentor-step-container{
    position: fixed;
    bottom: 0;
    height: auto;
    width: 100%;
    margin-left: -5px;
    margin-top: 0;

    &.unselected{
        background: #5d5d5d;
        border-top: 4px solid #5d5d5d;
        animation: 0.3s ease-out 0s 1 documentorDetailUnselected;

        @keyframes documentorDetailUnselected {
        0% {
          opacity: 0;
          @include transform(translateY(100%));
        }
        100% {
          opacity: 1;
          @include transform(translateY(0));
        }
      }
        .documentor-step{
          background: none;
          padding: 10px 5px;
          height: auto;

          .step-details-btn{
            display: block;
            text-align: center;
            color: $white;
          }

          .step-details-container{
            height: 0;
            overflow: hidden;
          }
        }
    }

    &.selected{
        background: #f1f1f1;
        border-top: 4px solid #ccc;
        height: 200px;
        overflow: auto;
        animation: 0.3s ease-out 0s 1 documentorDetailSelected;

        @keyframes documentorDetailSelected {
        0% {
          opacity: 0;
          @include transform(translateY(100%));
        }
        100% {
          opacity: 1;
          @include transform(translateY(0));
        }
      }

        .documentor-step{
          background: none;
          padding: 10px 5px;

          .step-details-btn{
            display: none;
          }

          .step-details-container{
            height: auto;
            overflow: visible;

            .step-details-close{
              display: block;
              color: $black;
              cursor: pointer;
              padding: 10px;
              margin: -10px;
            }

          }
        }
    }

  }

}

}
