[data-amplify-theme] {
	background: #FFF;
	height: 100%;
	min-height: 100%;

	--amplify-colors-brand-primary-10: $app-brand-color !important;
  --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20) !important;
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40) !important;
  --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60) !important;
  --amplify-colors-brand-primary-80: #0071BD !important;
  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90) !important;
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100) !important;


  --amplify-radii-small: 0 !important;
  --amplify-radii-medium: 0  !important;
  --amplify-radii-large: 0  !important;
  --amplify-space-small: 1rem  !important;
  --amplify-space-medium: 1.5rem  !important;
  --amplify-space-large: 2rem  !important;
  --amplify-border-widths-small: 1px  !important;
  --amplify-border-widths-medium: 2px  !important;
  --amplify-border-widths-large: 8px  !important;
}

[data-amplify-authenticator][data-variation=modal]{
	background: #fff !important;
}

.amplify-field{
	
}




.app-page-wrap {
	background: #FAFAFA;
	height: auto !important;
	z-index: 1;
	min-height: 100%;
	height: 100%;
	position: relative;
	overflow-x: hidden;

	.app-main-container{
		 height: 100%;

		.app-content-container{
			padding: 0 0 0 80px;
			position: relative;
			width: 100%;

			&.in{
				padding-top: 80px;
			}

			.page-content{
				  position: absolute;
				  left:0;
				  width: 100%;
				}

				h2{
					font-size: 3rem;
					color: #FF5722;
					margin: 25px 25px 25px;
				}

				h4{
					font-size: 2rem;
					color: #606060;
					margin: 0 0 30px 0;

				}

				h5{
					font-size: 1.8rem;
					color: #757575;
					font-weight: bold;
					margin: 0 0 30px 0;

				}

				> div{
					padding: 0 0 30px 80px;

					> div {
						padding: 0 30px;


					}
			}

			.extendable-height{
				display: flex;
			}
		}


		.info-container{
			background: #e1f5fe;
			padding: 18px;
			font-size: 1.4rem;
			color: $black;
			border-radius: 8px;
			font-weight: normal;
			margin: 0 0 20px 0;

			span.title{
				font-weight: bold;
			}

		}

		.static-side-drawer{
			background: #eee !important;
			text-align: center;
			box-shadow: none !important;
			padding: 40px !important;

			h1{
				color: $app-brand-color;
			}
		}

		.context-check{
			.alert{
				margin: 20px 20px 20px 420px;
			}
		}

		.container-w-centered-content{
			background: #FAFAFA !important;
			text-align: center;
			box-shadow: none !important;
			padding: 40px !important;

			h1{
				color: $app-brand-color;
			}
		}

		.key-value{
			font-size: 1.4rem;

			label{
				margin: 8px 0 8px 0;
				font-size: 1.4rem;
				display: block;
			}
		}

		@media (max-width: 767px) {
			.app-content-container{
				padding: 0 20px;
				top: 50px;

				h2{
					margin: 0 0 15px 0;
				}

				> div{
					padding: 20px;

					> div{
						 padding: 0;
					 }

				}
			}
		 }
	}

	.show-file-download-progress{
		bottom: 0;
		position: fixed;
		margin: 0 0 0 0px;
		z-index: 9999999;
		background: $dark-grey;
		width: 100%;
		padding: 10px;

		transform: scaleY(1);
  	transform-origin: bottom;
  	transition: transform 0.15s ease;


		div{
			font-size: 1.4rem !important;

			div{
				margin: 10px;
			}

			.material-icons{
				color: $white;
				padding: 5px;
				cursor: pointer;
				border-radius: 25px;

				&:hover{
					background: #464646;
				}

			}


			&:last-child{
				text-align: right;
			}
		}
	}


	.hide-file-download-progress{
		transform: scaleY(0);
	}

	.animate-flicker {
	   -webkit-animation: flickerAnimation 3s infinite;
	   -moz-animation: flickerAnimation 3s infinite;
	   -o-animation: flickerAnimation 3s infinite;
	    animation: flickerAnimation 3s infinite;
	}

	@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0.6; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.6; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.6; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.6; }
  100% { opacity:1; }
}
}
