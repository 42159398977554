@mixin box-shadow($h-shad,$v-shad,$shad-blur,$shad-spread,$shad-color,$inset:false) 
	{
		@if $inset
			{
			-webkit-box-shadow: inset $h-shad $v-shad $shad-blur $shad-spread $shad-color; 
			-moz-box-shadow: inset $h-shad $v-shad $shad-blur $shad-spread $shad-color; 
			box-shadow: inset $h-shad $v-shad $shad-blur $shad-spread $shad-color; 
			}
			
		@else
			{
			-webkit-box-shadow: $h-shad $v-shad $shad-blur $shad-spread $shad-color; 
			-moz-box-shadow: $h-shad $v-shad $shad-blur $shad-spread $shad-color; 
			box-shadow: $h-shad $v-shad $shad-blur $shad-spread $shad-color; 
			}
	}

@mixin transform($transforms) {
		-moz-transform: $transforms;
	  -o-transform: $transforms;
		 -ms-transform: $transforms;
	   -webkit-transform: $transforms;
		 transform: $transforms;
   }

@mixin transition($args) {
-webkit-transition: $args;
-moz-transition: $args;
-ms-transition: $args;
-o-transition: $args;
transition: $args;
   }
