.notification-container{

  .mobile-menu-icon
    {
      width: 50px;
      height: 50px;
      cursor: pointer;
      z-index:100;
      position: fixed;


      span{
        width: 30px;
        height: 35px;
        position: absolute;
        top:8px;
        left: 10px;
        @include transition(transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99));

        hr{
          margin: 0;
          position: relative;
          border-top: 0.1rem solid $black;
          @include transition(transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99));
        }

        &.top{
          @include transform(translateY(0));
            hr{
              top:8px;
              @include transform(rotateZ(360deg) );
            }
        }
        &.bottom{
          @include transform(translateY(0px));
            hr{
              top: 24px;
              @include transform(rotateZ(360deg));
            }
        }
        &.middle{
            opacity: 1;
            hr{
              top: 16px;
              width: 80%;
            }
        }
      }

      &.clicked{
        span{
          @include transition(transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99));
          hr{
            @include transition(transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99));
          }
          &.top{
                @include transform(translateY(8px));
                hr{
                  @include transform(rotateZ(-135deg));
                }
          }
          &.bottom{
              @include transform(translateY(-8px));
              hr{
                  @include transform(rotateZ(135deg));
              }
          }
          &.middle{
              opacity: 0;
          }
        }
      }
    }

    .badge{
      position: absolute !important;
      right: 15px;
      top: 10px;
      padding: 15px !important;
      background: $app-brand-color;
      border-radius: 50px;
      cursor: pointer;
      z-index: 99;

      &.count-0{
        > span{
            display: none !important;
        }
      }
    }
    .notification-content-container{
        top:0px !important;
        background: #FFF !important;

        .title{
          font-size: 2rem;
          padding: 20px 10px;
          text-align: left;
          color: #000;
          background: #ECECEC !important;

          .mobile-menu-icon{
            position: absolute;
            right: 15px;
            top: 10px;
          }
        }



        .notification{
          padding: 20px 15px;
          font-size: 1.4rem;
          color: $black;

          .date{
            font-weight:  bold;
          }

          &.new{
            background: #FFFDE7;
          }

          + .notification{
            border-top: 1px solid #ECECEC;
          }
        }


      }

    .menu-overlay{
        top:0px !important;
        background: $black !important;
        opacity: 0.5 !important;
      }
/*--------- Mobile View -----------------*/
    @media (max-width: 767px) {

      .notification-content-container{
        top: 50px !important;
      }
      .badge{
        position: fixed !important;
        background: none;
        right: 10px;
        top: 5px;
      }
    }
}
