.page-content{

  > div {padding: 0 30px;}

 .has-filter {
   .material-icons{
     display: none;
   }
 }


  


  /* ---- Recent Run Activity ----- */

  .recent-run{

    thead{
      tr{
        th{
          white-space: normal !important;
          font-size: 1.3rem !important;
          font-weight: 400 !important;

          &.status{

          }
        }
      }

    }

    tbody{
      tr{
         pointer-events: none;
          &:nth-child(odd){
            td{
              background: #fbfbfb !important;
            }
          }
          td{
            white-space: normal !important;
            width: 18%;
            font-size: 1.2rem !important;
            cursor: pointer;

            &:first-child{
              white-space: nowrap !important;
              }
              &.Pass,&.Fail,&.InProgress{
                div{
                  padding: 5px 10px;
                  color: $white;
                  border-radius: 4px;
                  width: 65px;
                  text-align: center;
                  border: 1px solid;
                  color: #FFF;
                }
              }



              &.Pass{
                div{
                      background: #43A047;
                      color: #FFF;
                  }
                }



                &.Fail{
                  div{
                        background: #C62828;
                    }
                  }

                  &.Inprogress{
                    div{
                    background: #EF6C00;
                   }

                  }

                &.run-indicator{
                  padding-left: 30px !important;
                  &.Pass{
                    > span{
                        width: 15px;
                        height: 15px;
                        display: none;
                        margin: 0 5px 0 -20px;
                        position: absolute;
                        &.Fail{
                          background: #1E88E5;
                          display: inline-block;
                        }
                        &.Slip{
                          background: #F4511E;
                          display: inline-block;
                        }
                    }

                  }
                }

            }
          }
        }
  }



/*---- Recent Run Results ----*/
.recent-run-results{
  label{
    display: block;
    color: rgb(158, 158, 158);
  }
}



  @media (max-width: 767px) {

    .has-filter {
      margin-bottom: 10px;
      .material-icons{
        display: block;
        position: absolute;
        right: -60px;
        top: 10px;
        width: 50px;
        text-align: center;
        height: 50px;
        border-radius: 50px;
        background: #eaeaea;
        padding: 13px 0 0 0;
        &.active{
          color: #FFF !important;
          background: #606060;
        }
      }
    }

      .filter-section{
        height: 0;
        overflow: hidden;
        display: none;


        &.show{
          animation: 0.3s ease-out 0s 1 filterAnimation;
          height: auto;
          overflow: visible;
          background: #fbfbfb;
          padding-bottom: 10px;
          margin: 0px;
        }
      }

          @keyframes filterAnimation {
          0% {
            opacity: 0;
            @include transform(translateY(-50px));
          }
          100% {
            opacity: 1;
            @include transform(translateY(0));
          }
        }



    .recent-run{
      thead{
        display: none;
      }

      tbody
      {
        tr{
          &:nth-child(odd){
            td{
              background: $white !important;
            }
          }
          td{
            position: relative;
            padding:10px 10px 10px 50% !important;
            display: block;
            font-size: 1.3rem !important;
            width: 100% !important;
            height: auto !important;

            &:before {
              content: attr(data-label);
              position: absolute;
              top: 10px;
              left: 6px;
              width: 45%;
              padding-right: 10px;
              white-space: nowrap;
            }

            &.status{
              &:before {
                top: 15px;
              }
            }

            &.run-indicator{
              padding-left: 50% !important;
              .Pass{
                left: 85%;
              }
              }
          }
        }
      }
    }

    .data-chips{
      > div {
        &:nth-child(3){
          margin-top: 0;
        }
        > div {
          border-radius: 12px;
          margin-bottom: 15px;
        }
    }
    }

  }



}
