.no-content-container{

  .material-icons{
    font-size: 60px ;
    color: #606060;
  }
  margin: 0;
  padding: 20px 15px;
  display: block;
  text-align: center;
  font-size: 18px;
  color: #606060;
  background: #fff;
  border-radius: 4px;
}
