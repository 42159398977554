.page-content{
  .query-response-title{
    font-size: 1.6rem;
    font-weight: bold;
    margin: 20px 0;
  }
  .query-response-data{
    overflow: auto;
    max-height: 300px;



    table{
      th{
        background: #f5f5f5;
      }
    }
  }


  .db-table-details{

    h4{
      padding: 20px;
      margin: 0 !important;
    }

    .db-table-details-content{
      max-height: 220px;
      overflow: auto;
      font-size: 1.6rem;
    }

    .MuiPaper-elevation1{
      box-shadow: none;

      .MuiAccordionSummary-root{
        background: #f5f5f5;
      }
    }


  }

  .params{

    h4{
      margin: 0 !important;
    }

    .param-value{
      font-size: 1.4rem;
      padding: 16px;
      font-weight: bold;
    }

    .param-container{
      padding: 16px 16px 4px;
      background: #F9F9F9;

      +.param-container{
        margin-top: 2px;
      }

    }



  }


}

.delete-param{
  font-size: 1.5rem;
  padding: 8px;
  &.title{
    font-weight: bold;
    padding: 0;
    color: #000;

  }
}

.context-select-table{

  thead{
    th{
      font-weight: bold !important;
      background: #EBEBEB;
    }
  }

  td{
    padding: 10px !important;

    &:first-child{
      color: $hyperlink;
    }

    label{
      margin: 0;
    }

    &.table-cell-title{
      font-size: 1.6rem !important;
      font-weight: bold;
      background: #FFF;
      color: #606060 !important;
      padding: 16px 16px !important;
    }

  }
}
