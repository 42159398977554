.empty-state-container{

  .material-icons{
    font-size: 60px ;
    color: #c5c5c5;
  }
  margin: 20px 0;
  padding: 40px 15px;
  display: block;
  text-align: center;
  font-size: 18px;
  color: #606060;
}
