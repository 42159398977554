.favorite-card{
  padding: 15px  !important;
  border: none !important;
  box-shadow: none !important;
  cursor: pointer;

  .title{
    padding:0 0 10px 0  !important;

    > span{
      font-size: 1.4rem  !important;
      line-height: 24px !important;
    }
  }

  .details{
    font-size: 1.2rem  !important;
    padding: 0 !important;
    color: #808080 !important;

    .value{
      color: #575757 !important;
    }
  }



  + .favorite-card{
    border-top: 1px solid #eee !important;
  }
  &:hover{
    background: #f5f5f5 !important;
  }
}
