html{
  scroll-behavior: smooth;
}

a{
  cursor: pointer;

  &:hover{
    text-decoration: none;
  }
}

label{
  font-weight: bold;
}

.MuiTooltip-popper{
  >div{
    font-size: 1.2rem !important;
  }
}

.fields-from-source{
  li{
    display: block;
    font-size: 1.4rem;
    padding: 12px 8px;
    pointer-events: none;

    + li{
      border-top: 1px solid #eee;
    }
  }
}

.inline-editable-field{
  padding: 16px 24px !important;
  background: #F3F3F3;
  border-radius: 4px;

  .MuiInput-underline.Mui-disabled{
    color: #606060 !important;

    &:before{
      border: none !important;
    }
  }
}

/*------ Page Content --------*/

.page-content{
  &.select-container{
      height: 0px;
      overflow: hidden;
      @include transition(all .3s ease);
      @include transform(translateX(-200%) );

      &.in{
        @include transform(translateX(0));
        height: auto;
        overflow: visible;
      }
    }

    &.results-container{
        height: 0px;
        overflow: hidden;
        display: none;
        @include transition(all .3s ease);
        @include transform(translateX(200%) );

        &.in{
          @include transform(translateX(0));
          height: auto;
          display: block;
          overflow: visible;


          .main{
            height: 0px;
            position: absolute;
            @include transition(all .3s ease);
            @include transform(translateX(-200%) );

            &.in{
              @include transform(translateX(0));
              height: auto;
              position: relative;
              min-height: 500px;
            }
          }

          .results{
            height: 0px;
            position: absolute;
            overflow: hidden;
            @include transition(all .3s ease);
            @include transform(translateX(200%) );
            &.in{
              @include transform(translateX(0));
              height: auto;
              position: relative;
            }
          }

        }

        .results-header{
          position: fixed;
          width: 100%;
          top: 15px;
        }

        .results-content{
          padding-top: 58px;
        }
      }




  .action-icon{
    color: #ababab;
    background: #e9e9e9;
    cursor: pointer;
    padding: 8px 9px;
    margin:-8px 0 0 10px;
    position: absolute;
    width: 38px;
    height: 38px;
    border-radius: 50%;

    &.active{
      color: #FFFF00;
      background: #ddd;
    }

    .material-icons{
      font-size: 20px;
    }
  }

  .row + .row{
    margin-top: 30px;
  }


  .flex-card-container{
    display: flex;
    align-content: stretch;
    flex-wrap: wrap;
  }



  .rounded-card{
    background: $white;
    border-radius: 4px;
    padding: 15px;
    height: 100%;
    border: 1px solid #EEE;

    &.transparent{
      background: none;
    }

    &.gray{
      background: #DADADA;
    }

    &.ligh-gray{
      background: #F9F9F9;
    }



    h3{
      font-size: 2rem;
      margin:0 0 12px 0;

      &.has-icon{
        padding: 0 0 0 50px;
        margin: 8px 0 15px 0;

        .material-icons{
          font-size: 4rem !important;
          position: absolute;
          left: 30px;
          top: 15px;

          &.red{
            color: $icon-red;
          }

          &.yellow{
            color: $icon-yellow;
          }

          &.green{
            color: $icon-green;
          }

          &.blue{
            color: $icon-blue;
          }

          &.pink{
            color: $icon-pink;
          }

          &.orange{
            color: $icon-orange;
          }
        }
      }
      &.has-filter{
        margin: 8px 0 15px 0;

        .material-icons{
          font-size: 4rem !important;
          position: absolute;
          right: 30px;
          top: 15px;
          color: #ddd;

          &.red{
            color: #F44336;
          }
        }
      }
    }

    + .rounded-card{
      margin-top: 25px;
    }

    .push-right{
      float: right;
    }

    .pill-highlight{
      background: #eee;
      padding: 4px 8px;
      border-radius: 8px;
      width: auto;
      font-size: 1.3rem;
    }

    .rounded-card-subtitle{
      font-size: 1.8rem;
      font-weight: bold;
      margin: 16px 0px 8px 10px;
      color: #606060;

    }

    &.max-height{
      max-height: 500px;
    }
  }

  .label-value-pair{
    label{
      font-size: 1.6rem !important;
    }
    div{
      font-size: 1.6rem !important;
    }
  }

  .MuiFormControl-root{

    .MuiFormHelperText-root{
      font-size: 1.4rem !important;
    }
  }

.display-status{

  font-size: 1.4rem;

.material-icons{

  font-size: 14px;
  margin: 4px 0 0 -20px;
  position: absolute;

  &.active{
    color:$green;
  }

  &.inactive{
    color:$red;
  }

    }
  }


.MuiToggleButton-root{

  .MuiToggleButton-label{
    font-size: 1.2rem;
    line-height: 1.2rem !important;
  }

  &.Mui-selected{
    background: $app-brand-color !important;
    color: $white;
  }

}

.MuiAccordion-root{
  border: none !important;
  box-shadow: none !important;

  &:before{
    background: none !important;
  }

  + .MuiAccordion-root{
    border-top: 1px solid #eee !important;
  }

  .MuiCollapse-container{
    background: #f5f5f5;
    border-radius: 4px;
  }

.MuiAccordionSummary-root{

  &.Mui-expanded{
    min-height: 20px !important;
  }





.MuiAccordionSummary-content{
  font-size: 1.6rem;
  font-weight: normal;
  padding-left: 24px;
  display: block !important;

  .material-icons{
    display: none;
  }

  &.Mui-expanded{
    margin: 12px 0 !important;
  }

.highlight-content{
  margin: 0 4px;
  font-weight: bold;
}

.desc-content{
  display: block;
  font-size: 1.3rem;
  margin: 5px 0 0 5px;
}
.title-pill{
  border-bottom: 1px dotted #ddd;
  margin: 0 8px;
  border-radius: 4px;
}

}



.MuiAccordionSummary-expandIcon{
  position: absolute;
  left: 0;
  top:0;

  &.Mui-expanded{
    transform: rotate(90deg);
        }
      }
    }
  }
}
.disable-field{
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.tag-delete{
  padding: 4px 8px;
  background: #ffcdd2;
  color: #000;
  font-size: 1.2rem;
  border-radius: 4px;
  width:max-content;
  margin-top: 8px !important;
}
.note{
  font-size: 1.4rem;
  padding: 16px 0;
  span{
    font-weight: bold;
  }
}

.error{
  font-size: 1.4rem;
  padding: 16px 0;
  color: $icon-red;
  span{
    font-weight: bold;
  }
}

.MuiTextField-root{
  .MuiSvgIcon-root{
    font-size: 1.7rem;
  }
}

.MuiPickersBasePicker-container{
  .MuiTypography-root{
    font-size: 1.3rem !important;

    &.MuiTypography-body1{
      font-size: 1.4rem !important;
    }
  }
  .MuiSvgIcon-root{
    font-size: 2.0rem !important;
  }
}

.continue-link{
  font-size: 1.5rem;
  padding: 8px;

  .material-icons{
    font-size: 1.5rem;
    position: absolute;
    margin-top: 2px;
  }
}

.snack-bar{
        top:0 !important;
        transform: 'translate3d(0px, -50px, 0px)';
        width: 100% !important;

        > div{
          margin: 0 auto;
        }
      }



/*------ Fixed Page Nav Contianer --------*/


.left-sticky-container{
  position: sticky;
  left: 0;
}

.top-sticky-container{
  position: sticky;
  top: 0;
}


.show-more{
  background: #eee;
  padding: 10px;
  font-size: 1.6rem;
  cursor: pointer;
  margin-bottom: 0px;
  border-radius: 0px;
  text-align: center;
  margin:0;

  &:hover{
    background: #ddd;
  }
}

.top-section{
  background: #EEE;
  margin: -15px -15px 25px -15px;
  padding: 20px;
  border-radius: 4px 4px 0 0;
}

.filter-btn{
  padding: 8px 16px 8px 32px;
  font-size: 1.6rem;
  border: 1px solid #B1B1B1;
  color: #000;
  border-radius: 8px;
  margin: 5px;
  position: absolute;
  right: 25px;

  .material-icons{
    font-size: 1.6rem;
    position: absolute;
    margin: 3px 0 0 -20px;
  }

  &.active{
    background: #bdbdbd;
  }
}

.filter-side-sheet{
  width: 360px;
  background: $white;
  padding-top: 80px;

  .title{
    padding: 20px;
    background: #eee;
    position: fixed;
    top:0;
    width: 360px;
    margin: 0;
    z-index: 99;


    h3{
      margin: 0;
      font-size: 2rem !important;
    }

    a{
      color: $black;
    }
  }

  .filter-content{
    padding:24px 16px;
  }

  .cta-content{
      padding: 20px 30px;
      text-align: center;

      .btn{
        width: 80%;
        margin: 0;

        + .btn{
          margin-top:20px;
        }
      }
  }

}


.MuiDrawer-paperAnchorBottom{
  height: 75% !important;
}

.bottom-sheet{
  background: $white;

  .title{
    padding: 20px;
    background: #eee;


    h3{
      margin: 0;
      font-size: 2rem !important;
    }

    a{
      color: $black;
    }
  }

  .bottom-sheet-content{
    padding: 16px;
  }

  .recon-tabs{
    margin: 0px !important;
  }

}

.row-pills{
  padding: 8px;
  span{
    background: #eee;
    padding: 8px 16px;
    border-radius:8px;
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 10px;

    + span{
      margin-left: 16px;
    }

    label{
      font-weight:400;
      margin:0 0 15px 0 !important;
    }

    &.blue{
      background: #bbdefb;
    }


    &.green{
      background: #dcedc8;
    }

    &.red{
      background: #ffcdd2;
    }

    &.orange{
      background: #ffe0b2;
    }
  }
}

.inline-label{
  display: inline !important;
}

.overview-card{


  border-right: 1px solid #eee;
  padding: 10px;

  &.full-border{
    border: 1px solid #eee;
  }

  .title{
    font-size: 2.4rem;
    text-align: center;
    margin-bottom: 10px;
  }
  .value{
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
  }
}


.page-nav-container{
  position: fixed;
  width: 100%;
  top: 0;
  background: $white;
  z-index: 5;
  left: 80px;
  padding: 20px !important;
  height: 59px;
  border-bottom: 1px solid #eee;

  a{
    padding: 0;
    font-size: 1.4rem;
  }

&.overlap{
  top: -50px;
}


}

.menu-card{
  background: #EFEFEF !important;
  cursor: pointer;
  padding-left:60px !important;
  border: 1px solid #ddd !important;

  h3{
    color: $hyperlink;
  }

  .material-icons{
    font-size: 3rem;
    position: absolute;
    margin-left: -40px;
    color: #606060;
  }
}

.sub-module{
  padding: 0 !important;

  > div{
    padding: 0 30px;
  }
}

/*--- Main Theme Styling ---*/

.btn-container{
  .MuiButtonBase-root{
    padding: 8px 16px !important;
    font-size: 1.4rem !important;
    text-transform: capitalize !important;
  }
}

.MuiTabs-root{
  button{
    font-size: 1.5rem !important;
    text-transform: capitalize !important;
  }
}

.flyout-menu{
  .MuiPaper-root{

    margin: -24px 0 0 -8px;
    min-width: 100px;

    li{
      font-size: 1.4rem !important;
    }

  }
}


/*--------- Mobile View -----------------*/
@media (max-width: 767px) {

  body{
    -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
  }
  .page-content{
    &.results-container{
      .results-content{
        padding-top: 30px;
      }
    }
  }

  h3{
    margin-top: 10px;
  }



  [class*="col-"] + [class*="col-"] {
    margin-top: 15px;
  }

}
