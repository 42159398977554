.dropdown{

  width: 100% !important;

  label{
    color: #000 !important;
  }

  div{
    hr:last-child{
      border-bottom-color: $app-brand-color !important;
    }
  }
}


@media (max-width: 767px) {
  .dropdown{
      width: 100% !important;
   }
 }
