  header{
    display: none;
  }

/*--------- Mobile View -----------------*/
@media (max-width: 767px) {
  header{
      background: $app-brand-color;
      height: 50px;
      color: $white;
      position: fixed;
      width: 100%;
      z-index: 99;
      display: block;

      .header-container{
        margin: 0;

        .header-title{
          text-align: center;
          padding: 12px 15px 0;
          font-size: 2rem;
          color: $app-brand-text;
        }
      }
    }
  }
