.myspace-card{

  margin-top: 15px;
  margin-bottom: 15px;
  background: $white !important;
  height: 120px;
  background: #FFF !important;
  border-left: 8px solid;

  + .myspace-card{
  margin-top: 30px;
  }

  .content{

    .title{
          cursor: pointer;
          > span{
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 90%;
            overflow: hidden;
          }
    }
  }

  &.pass{
    border-left-color: #43A047 !important;
    .material-icons {
      color: #43A047;
    }
  }

  &.warning{
    border-left-color: #43A047 !important;
    .material-icons {
      color: #0277BD;
    }
  }

  &.slip{
    border-left-color: #43A047 !important;
    .material-icons {
      color: #EF6C00;
    }
  }

  &.fail{
    border-left-color: #E53935 !important;
    .material-icons {
      color: #E53935;
    }
  }

  &.not-started{
    border-left-color: #BDBDBD !important;
    .material-icons {
      color: #BDBDBD;
    }
  }


  .material-icons{
    font-size: 30px !important;
  }



  .description{
    padding: 0 16px 16px 16px !important;
    font-size: 1.4rem !important;
  }

  .footer{
    padding: 10px 0;

    .footer-content{
      padding: 0 12px 5px !important;
    }
    span{
      font-size: 1.2rem !important;
      color: $black !important;
    }

    .icon-indicator{
      margin: -15px 0 0 0px;
      padding-left: 25px;
    }

    .remove{
      margin: -10px 0 0 0;
      padding: 0 15px 0 0;
      bottom: 15px;
      text-align: right;
      .material-icons{
        position: relative !important;
        right: 8px;
        margin: 0;
        font-size: 20px !important;
        padding: 0 10px;
        border-radius: 20px;
        color: #606060;
      }
    }
  }

  .row + .row{
    margin-top: 0px;
  }

}
/*--------- Mobile View -----------------*/
@media (max-width: 767px) {
  .myspace-card{
    height: auto;

    + .myspace-card{
    margin-top: 20px !important;
    }

    .content{

      .title{
            > span{
              white-space: normal;
              text-overflow: ellipsis;
              width: 90%;
              overflow: visible;
            }
      }
    }

    .footer{
      height: 35px;
      padding: 10px 0 0 0;
    }
  }

}
