.MuiAutocomplete-root{

  label{
    font-size: 1.8rem !important;
  }
}

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    width: 100%;
    padding: 10px 20px;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;

    &:focus{
      outline: none;
    }

    &::placeholder{
      font-style: italic;
      color: #c5c5c5;
    }



  }



  .react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: 43px;
    width: 100%;
    border: 1px solid #eee;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }

 .too-many{
    .react-autosuggest__container--open .react-autosuggest__suggestions-container {
      display: block;
      position: absolute;
      top: 43px;
      width: 100%;
      border: 1px solid #eee;
      background-color: #fff;
      font-family: Helvetica, sans-serif;
      font-weight: 300;
      font-size: 16px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 2;

      &:after{
        content: "Too many matching results. Please refine your search.";
        color: #000;
        font-size: 12px;
        font-weight: normal;
        text-align: left;
        display: block;
        background: #DFDFDF;
        margin: 0 0 0 0;
        padding: 7px 0 7px 18px;
        border-top: 1px dashed #606060;
      }
    }
}

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;


    .support-text{
      color: #BBBBBB;
    }

  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion:not(:first-child) {
    border-top: 1px solid #eee;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #F1F1F1;
    color: #606060;
  }

  .suggestion-content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
  }


  .name {
    line-height: 25px;
  }

  .highlight {
    color: #ee0000;
    font-weight: bold;
  }

  .react-autosuggest__suggestion--focused .highlight {
    color: #120000;
  }
