html, body, p {
	margin: 0;
	padding: 0;
}

html, body,#root{
	height: 100%;
}

body{
	overflow-x: hidden;
}
