.pop-selector{

  border: 1px solid #eee;
  border-radius: 6px;
  text-align: left;
  width: 100% !important;
  background-color: $white !important;
  margin-bottom: 15px;

  label{
    top:35px !important;
    display: block;
    width: 100%;
    left: 15;
    padding: 0 15px;
    color: #673AB7 !important;
  }

  &.no-value{

    text-align: left;
    background-color: $white !important;

    label{
      top:25px !important;
      color: #606060 !important;
      left:0;
    }
  }

  > div{
    margin-top: 0 !important;

    > div{

      > div{
        padding: 5px 12px 0 !important;
      }

    }

  }

  button{
    top: 12px !important;
  }

  div{
    hr{
      border: none !important;
    }
  }

}

@media (max-width: 767px) {
  .pop-selector{
    margin-bottom: 0;
   }
 }
