.wlgroup-card{

  margin-top: 15px;
  margin-bottom: 15px;
  background: $white !important;
  height: 132px;
  background-color: #EAEAEA !important;

  + .wlgroup-card{
  margin-top: 30px;
  }

.highlights{

  text-align: left;
  padding: 0 15px 0 25px;

  > div{
    margin: 5px 4% 0;
    float: left;
    padding: 8px 4px;
    height: 35px;
    width: 35px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;

     &.fail{
          background: #E53935 !important;
          color: #FFF !important;
          border-radius: 4px;
        }

      &.threshold{
        color: #FFF !important;
        background: #0277BD;
        position: relative;

        &:before {
        content: "";
        width: 35px;
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        border-bottom: 10px solid #0277BD;
        border-left: 10px solid #EAEAEA;
        border-right: 10px solid #EAEAEA;
    }
      &:after {
        content: "";
        width: 35px;
        height: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 10px solid #0277BD;
        border-left: 10px solid #EAEAEA;
        border-right: 10px solid #EAEAEA;
    }

        span{
          margin: 2px 0 0 0;
        }
      }

      &.benchmark{
        background: #EF6C00 !important;
        color: #FFF !important;
        border-radius: 25px;
      }

      &.items0{
        display: none !important;
        /*
        background: #C5C5C5 !important;
        &.threshold{

                &:before {
                border-bottom: 10px solid #C5C5C5;
            }
              &:after {
                border-top: 10px solid #C5C5C5;
            }

          }
          */
      }
  }



}

  .shared{
    position: absolute;
    right: 20px;
    top: 5px;
    z-index: 2;
    color: #c5c5c5;

    i{
       @include transform(scale(-1, 1));
    }
  }
  .title{
    padding-right: 30px !important;
    cursor: pointer;
    background-color: $white;

     span:first-child{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
   }

  }


  .description{
    padding: 0 16px 16px 16px !important;
    font-size: 1.4rem !important;
  }

  .footer{
    text-align: center;
    padding: 7px 0;

    .footer-content{
      padding: 0 12px 5px !important;
    }
    span{
      font-size: 1.2rem !important;
      color: $black !important;
    }

    .remove{
      margin: 0 0 0 -2px;
      padding: 0 15px 0 0;
      bottom: 14px;
      text-align: right;
      position: absolute;
      .material-icons{
        position: relative !important;
        right: 8px;
        margin: 0;
        font-size: 20px !important;
        padding: 0 10px;
        border-radius: 20px;
        color: #606060;
      }
    }
  }

  .row + .row{
    margin-top: 0px;
  }

  &.shared{


      .title{
        background: none;
        cursor: default;
        padding-bottom: 10px !important;

        > span{
          color: $black !important;
        }

      }

  }

}

/*--------- Mobile View -----------------*/
@media (max-width: 767px) {
  .wlgroup-card{

    height: auto;


    .title{
      margin-bottom: 30px !important;

        span:first-child{
        overflow: visible;
        white-space: normal;
       }
     }


     .highlights{

       > div{
         margin: -22px 4% -5px 4%;
       }

    }
  }

  &.shared{

      .title{
        margin-bottom: 10px !important;
      }

      .btn{
        margin-bottom: 15px !important;
      }

  }
}
